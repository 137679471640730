/* COLOR PALETTE */
:root {
    --white: #ffffff;
    --black: #000000;
    --gray50: #fafafa;
    --gray100: #f5f5f5;
    --gray200: #eeeeee;
    --gray300: #dbdbdb;
    --gray400: #cccccc;
    --gray500: #999999;
    --gray600: #757575;
    --gray700: #444444;
    --gray800: #333333;
    --gray900: #111111;
    --coolgray: #828c9c;
    --light-blue: #f0f3f6;
    --lg-red: #a50034;
    --bg-default: #f7f7f7;
    --bg-blue: #7496cc;
    --focus-border: #191919;
    --warning: #ff3257;
    --hint: #8a8a8a;
    --red: #ff0000;
}

/* Noto Sans KR */

/* noto-sans-kr-100 - latin_korean */
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: local('☺'),
    url('fonts/kr/NotoSansKR/noto-sans-kr-v12-latin_korean-100.woff2') format('woff2'),
    url('fonts/kr/NotoSansKR/noto-sans-kr-v12-latin_korean-100.woff') format('woff');
}

/* noto-sans-kr-300 - latin_korean */
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: local('☺'),
    url('fonts/kr/NotoSansKR/noto-sans-kr-v12-latin_korean-300.woff2') format('woff2'),
    url('fonts/kr/NotoSansKR/noto-sans-kr-v12-latin_korean-300.woff') format('woff');
}

/* noto-sans-kr-regular - latin_korean */
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: local('☺'),
    url('fonts/kr/NotoSansKR/noto-sans-kr-v12-latin_korean-regular.woff2') format('woff2'),
    url('fonts/kr/NotoSansKR/noto-sans-kr-v12-latin_korean-regular.woff') format('woff');
}

/* noto-sans-kr-500 - latin_korean */
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: local('☺'),
    url('fonts/kr/NotoSansKR/noto-sans-kr-v12-latin_korean-500.woff2') format('woff2'),
    url('fonts/kr/NotoSansKR/noto-sans-kr-v12-latin_korean-500.woff') format('woff');
}

/* noto-sans-kr-700 - latin_korean */
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: local('☺'),
    url('fonts/kr/NotoSansKR/noto-sans-kr-v12-latin_korean-700.woff2') format('woff2'),
    url('fonts/kr/NotoSansKR/noto-sans-kr-v12-latin_korean-700.woff') format('woff');
}

/* noto-sans-kr-900 - latin_korean */
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 900;
    src: local('☺'),
    url('fonts/kr/NotoSansKR/noto-sans-kr-v12-latin_korean-900.woff2') format('woff2'),
    url('fonts/kr/NotoSansKR/noto-sans-kr-v12-latin_korean-900.woff') format('woff');
}
